<template>
  <b-card title="Content">
    <b-tabs>
    <b-tab :active="currentRouteName=='course-content'"
     @click="$router.push('/course-content')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Course Content</span>
        </template>

      </b-tab>
      <!-- <b-tab :active="currentRouteName=='long-course-content'"
     @click="$router.push('/long-course-content')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Diplomas Content</span>
        </template>

      </b-tab> -->
      <b-tab :active="currentRouteName=='category-content'"
       @click="$router.push('/category-content')" v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Category Content </span>
        </template>
       
<!-- <CategoryContent></CategoryContent>/ -->
      </b-tab>
      <b-tab :active="currentRouteName=='category-page-content'"
      @click="$router.push('/category-page-content')" v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Category Page Content </span>
        </template>
       
<!-- <CategoryPageContent></CategoryPageContent> -->
      </b-tab>
       <b-tab :active="currentRouteName=='city-content'"
        @click="$router.push('/city-content')" v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"  >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>City Content </span>
        </template>
       
<!-- <CityContent></CityContent> -->
      </b-tab>
      <b-tab :active="currentRouteName=='category-in-city-content'" 
       @click="$router.push('/category-in-city-content')" v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Category in City</span>
        </template>
       
<!-- <CategoryinCityContent></CategoryinCityContent> -->
      </b-tab>
      
     
    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <CourseContent v-if="route.name === 'course-content' "  />
      <router-view  v-else>
          <component :is="Component" />
        </router-view>
  </router-view>
  
  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'



import CourseContent from './CourseContent.vue';
import CategoryContent from './CategoryContent.vue';
import CategoryPageContent from './CategoryPageContent.vue';
import CityContent from './CityContent.vue';
import CategoryinCityContent from './CategoryinCityContent.vue';


export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
  
    CourseContent,
    CategoryContent,
    CategoryPageContent,
    CityContent,
    CategoryinCityContent
},
data() {
    return {
   role:{}
    }
  },
  created(){
    this.role=JSON.parse(localStorage.getItem('userData'))
  }
  ,
   computed: {
    currentRouteName() {
        return this.$route.name;
    }}}
</script>
